import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ShareButton from "../../components/share-button"
import BtnCircle from "../../components/button-circle"
import GalleryCategoryTilesMobile from "../../components/gallery-category-tiles-mobile"
import GalleryCategoryTiles from "../../components/gallery-category-tiles"
import ContactForm from "../../components/form"
import BtnLeft from "../../components/button-left"

const KontaktIndex = ({ location }) => {
  const pageData = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/kontakt/" }) {
        frontmatter {
          title
        }
        html
      }
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(blog)/" }
          frontmatter: { inGallery: { eq: true } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 10
      ) {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            date(formatString: "YYYY-MM-DD")
            title
            description
          }
        }
      }
    }
  `)
  const posts = pageData.allMarkdownRemark.nodes
  const pageTitle = pageData.markdownRemark.frontmatter.title
  const pageBody = pageData.markdownRemark.html
  const featuredImage = getImage(
    pageData.markdownRemark.frontmatter.featuredImage
  )

  return (
    <Layout location={location} title={pageTitle}>
      <div className="section">
        <SEO title="Kontakt" />
        <div className="columns">
          <div className="column is-8-desktop is-12-tablet content-container has-background-dark is-6">
            <div className="columns is-mobile">
              <div className="column has-text-left ml-3">
                <BtnLeft
                  className=""
                  path={"/"}
                  description={"STRONA GŁÓWNA"}
                />
              </div>
              <div className="column has-text-right">
                <ShareButton location={location} />
              </div>
            </div>
            {/* <section className="section has-text-justified has-text-weight-light is-size-5 pb-4 pt-3">
              <div
                className="md-has-dropcap md-article"
                dangerouslySetInnerHTML={{ __html: pageBody }}
              />
            </section> */}
            <section className="pt-3">
              <h2 className="title is-3 is-family-secondary has-text-weight-light has-letter-spacing-1 is-title-underlined is-relative">
                KONTAKT
              </h2>
            </section>

            <div className="columns is-flex is-multiline is-size-6 has-text-weight-light has-letter-spacing-1 mt-6 is-uppercase">
              <div className="column is-12-mobile is-half-tablet">
                <p className="is-family-secondary mb-1">
                  Adres korespondencyjny
                </p>
                <p>Wojciech Rutkowski</p>
                <p>ul. Człuchowska 19</p>
                <p>01-360 Warszawa</p>
              </div>
              <div className="column is-12-mobile is-half-tablet">
                <p className="is-family-secondary mb-1">Kontakt mailowy</p>
                <a
                  className="is-uppercase"
                  href={`mailto:wojrut6@wp.pl?subject=Kontakt ze strony obrazyrutkowski.pl`}
                  target="_blank"
                >
                  wojrut6@wp.pl
                </a>
              </div>
              <div className="column is-12-mobile is-half-tablet">
                <p className="is-family-secondary mb-1">Kontakt telefoniczny</p>
                <p>Tel. kom.: 608 835 610</p>
              </div>
              <div className="column is-12-mobile is-half-tablet">
                <p className="is-family-secondary mb-1">
                  Media społecznościowe
                </p>
                <a
                  target="_blank"
                  href="https://m.facebook.com/pages/biz/warszawa/Wojciech-Rutkowski-125242080861242/?locale2=pl_PL"
                >
                  <div className="is-flex">
                    <div className="material-icons-outlined md-18">
                      facebook
                    </div>
                    <p className="ml-2">Profil artysty na Facebooku</p>
                  </div>
                </a>
              </div>
            </div>

            <section>
              <p className="is-family-secondary mb-1 is-size-6 has-text-weight-light has-letter-spacing-1 mt-6 is-uppercase">
                Formularz kontaktowy
              </p>

              <ContactForm />
            </section>

            <section className="is-hidden-desktop">
              <GalleryCategoryTilesMobile />
            </section>
          </div>
          <div className="column is-offset-1 is-3 is-1-tablet is-hidden-touch">
            <GalleryCategoryTiles />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default KontaktIndex
